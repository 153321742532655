<template>
  <v-app>
    <AppBar v-if="headerType != 'noNeedHeader'"></AppBar>
    <router-view :key="$route.fullPath" />
  </v-app>
</template>

<script>
import AppBar from "./components/NavBar";
export default {
  name: "App",

  components: {
    AppBar,
  },

  data: () => ({
    headerType: "",
  }),
  created() {
    this.$store.watch((state, getters) => {
      this.headerType = getters.getHeaderType;
    });
  },
};
</script>
<style>
#app {
  /* font-family: 'Avenir', Helvetica, Arial, sans-serif; */
  /* font-family: 'M PLUS Rounded 1c', sans-serif; */
  font-family: "Barlow", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #eeeff1 !important; */
}
</style>
