<template>
  <v-main>
    <v-app-bar
      flat
      color="white"
      short
      fixed
      clipped-left
      style="padding-top: 10px; padding-left: 20px"
    >
      <v-icon @click="route_home" large color="black">mdi-arrow-left </v-icon>
      <!-- <v-toolbar-title>
        <div @click="route_home">
          <img src="../assets/my_logo.png" alt="my logo" width="40" />
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="hidden-sm-and-down">
        <v-btn text large class="header-text" @click="route_home">Home</v-btn>
        <v-btn text large class="header-text" @click="route_about_us"
          >About Us</v-btn
        >
      </div> -->
    </v-app-bar>
    <div>
      <div
        :style="{
          paddingLeft: generalSpacingLeftRight + '%',
          paddingRight: generalSpacingLeftRight + '%',
        }"
      >
        <div align="center" :style="{ marginTop: screenHeight / 2 + 'px' }">
          <h1
            style="
              color: #f36823;
              font-weight: 300;
              font-size: 52px;
              line-height: 55px;
            "
          >
            `Bring Automation Into Our Daily Life`
          </h1>
          <h1 style="font-weight: 300; font-size: 22px; line-height: 55px">
            Born to meet expectations by code.
          </h1>
        </div>
        <div :style="{ marginTop: screenHeight / 2 + 'px' }">
          <v-row align="center" justify="center"
            ><v-col cols="2" sm="3" xs="2" md="4" lg="4" xl="4">
              <v-img
                width="100%"
                class="mx-auto"
                src="../assets/working_image.jpg"
              ></v-img>
            </v-col>
            <v-col cols="10" sm="9" xs="10" md="8" lg="8" xl="8">
              <p
                class="content"
                :style="{ paddingLeft: headerLeftSpacing + 'px' }"
              >
                TECHTROMECH established since 2021 with a group of engineers who
                has great passion and talent towards programming and
                communications. TECHTROMECH strive towards creating an
                environmental friendly automated society as we produced
                different customized websites and apps every year to help and
                bring more businesses into the digital world.
              </p>
            </v-col>
          </v-row>
        </div>
        <div style="margin-top: 100px">
          <v-row align="center" justify="center">
            <v-col cols="2" sm="3" xs="2" md="4" lg="4" xl="4">
              <v-img
                width="50%"
                class="mx-auto"
                src="../assets/vision.png"
              ></v-img>
            </v-col>
            <v-col cols="10" sm="9" xs="10" md="8" lg="8" xl="8">
              <div :style="{ paddingLeft: headerLeftSpacing + 'px' }">
                <div class="d-inline-flex flex-column">
                  <div>
                    <p class="header-subtitle">Our Vision</p>
                  </div>
                </div>
                <div>
                  <p class="content">Bring Automation Into Our Daily Life.</p>
                </div>
                <!-- <div style="margin-bottom: 1000px;"> </div> -->
              </div>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" style="margin-top: 60px">
            <v-col cols="2" sm="3" xs="2" md="4" lg="4" xl="4">
              <v-img
                width="50%"
                class="mx-auto"
                src="../assets/mission.png"
              ></v-img>
            </v-col>
            <v-col cols="10" sm="9" xs="10" md="8" lg="8" xl="8">
              <div :style="{ paddingLeft: headerLeftSpacing + 'px' }">
                <div class="d-inline-flex flex-column">
                  <div>
                    <p class="header-subtitle">Our Mission</p>
                  </div>
                </div>
                <div>
                  <p class="content">
                    TECHTROMECH strive towards creating an environmental
                    friendly automated society.
                  </p>
                </div>
                <!-- <div style="margin-bottom: 1000px;"> </div> -->
              </div>
            </v-col>
          </v-row>
        </div>
        <div style="margin-top: 150px">
          <div align="center">
            <h1
              style="
                color: #f36823;
                font-weight: 300;
                font-size: 52px;
                line-height: 55px;
              "
            >
              Values we bring to you...
            </h1>
            <v-row style="margin-top: 100px">
              <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                <v-img
                  width="150px"
                  height="150px"
                  class="mx-auto"
                  src="../assets/adaptability.png"
                >
                </v-img>
                <p class="content">Adaptability</p>
              </v-col>
              <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                <v-img
                  width="150px"
                  height="150px"
                  class="mx-auto"
                  src="../assets/capability.png"
                >
                </v-img>
                <p class="content">Capability</p>
              </v-col>
              <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                <v-img
                  width="150px"
                  height="150px"
                  class="mx-auto"
                  src="../assets/collaborative.png"
                >
                </v-img>
                <p class="content">Collaborative</p>
              </v-col>
              <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                <v-img
                  width="150px"
                  height="150px"
                  class="mx-auto"
                  src="../assets/competitive.png"
                >
                </v-img>
                <p class="content">Competitive</p>
              </v-col>
              <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                <v-img
                  width="150px"
                  height="150px"
                  class="mx-auto"
                  src="../assets/customer_focused.png"
                >
                </v-img>
                <p class="content">Customer Focused</p>
              </v-col>
              <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                <v-img
                  width="150px"
                  height="150px"
                  class="mx-auto"
                  src="../assets/efficiency.png"
                >
                </v-img>
                <p class="content">Efficiency</p>
              </v-col>
              <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                <v-img
                  width="150px"
                  height="150px"
                  class="mx-auto"
                  src="../assets/flexibility.png"
                >
                </v-img>
                <p class="content">Flexibility</p>
              </v-col>
              <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                <v-img
                  width="150px"
                  height="150px"
                  class="mx-auto"
                  src="../assets/performance.png"
                >
                </v-img>
                <p class="content">Performance</p>
              </v-col>
              <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                <v-img
                  width="150px"
                  height="150px"
                  class="mx-auto"
                  src="../assets/expertise.png"
                >
                </v-img>
                <p class="content">Expertise</p>
              </v-col>
              <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                <v-img
                  width="150px"
                  height="150px"
                  class="mx-auto"
                  src="../assets/innovation.png"
                >
                </v-img>
                <p class="content">Innovation</p>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 100px">
      <v-img width="100%" height="5" src="../assets/underline.svg"></v-img>
    </div>
    <div
      :style="{
        paddingLeft: generalSpacingLeftRight + '%',
        paddingRight: generalSpacingLeftRight + '%',
      }"
    >
      <div style="margin-top: 120px; margin-bottom: 120px">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
            <div style="margin-bottom: 100px">
              <v-img
                width="100%"
                class="mx-auto"
                src="../assets/techtrologo_big.png"
              ></v-img>
            </div>
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
            <div :style="{ paddingLeft: headerLeftSpacing + 'px' }">
              <div class="d-inline-flex flex-column">
                <div>
                  <p class="header-subtitle">Services</p>
                </div>
              </div>
              <div>
                <p class="content">Web Design Services</p>
                <p class="content">Mobile Application Development</p>
                <p class="content">MES / ERP / SAP</p>
                <p class="content">E-Commerce</p>
                <p class="content">Backend Development</p>
                <p class="content">Development Consultant</p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
            <div :style="{ paddingLeft: headerLeftSpacing + 'px' }">
              <div class="d-inline-flex flex-column">
                <div>
                  <p class="header-subtitle">Contact</p>
                </div>
              </div>
              <div>
                <v-row>
                  <v-col cols="2">
                    <v-icon>mdi-map-marker-multiple </v-icon>
                  </v-col>
                  <v-col cols="10">
                    <a :href="'https://goo.gl/maps/KNqw5kQDuT1yzBPGA'">
                      <p class="content">
                        2, Jalan 1/149l, Bandar Baru Sri Petaling, 57000 Kuala
                        Lumpur, Wilayah Persekutuan Kuala Lumpur
                      </p>
                    </a>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="2">
                    <v-icon>mdi-phone-in-talk </v-icon>
                  </v-col>
                  <v-col cols="10">
                    <a :href="'https://wa.me/' + contact_one">
                      <p class="content">
                        {{ contact_one }}
                      </p>
                    </a>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-icon>mdi-phone-in-talk </v-icon>
                  </v-col>
                  <v-col cols="10">
                    <a :href="'https://wa.me/' + contact_two">
                      <p class="content">
                        {{ contact_two }}
                      </p>
                    </a>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-icon>mdi-email-edit </v-icon>
                  </v-col>
                  <v-col cols="10">
                    <a :href="'mailto:' + contact_email_one">
                      <p class="content">
                        {{ contact_email_one }}
                      </p>
                    </a>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <v-icon>mdi-email-edit </v-icon>
                  </v-col>
                  <v-col cols="10">
                    <a :href="'mailto:' + contact_email_two">
                      <p class="content">
                        {{ contact_email_two }}
                      </p>
                    </a>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- mission Techtromech Sdn.Bhd. strive towards creating an environmental
    friendly automated society. services Consultation, training, mobile app and
    website development contact information techtromech.com
    https://www.facebook.com/techtromech -->
  </v-main>
</template>

<script>
export default {
  data: function () {
    return {
      contact_one: "0162843127",
      contact_two: "0179885556",
      contact_email_one: "voon@techtromech.com",
      contact_email_two: "jiawei@techtromech.com",
    };
  },
  created() {
    var payload = {
      headerType: "noNeedHeader",
    };
    this.$store.dispatch("updateHeaderType", payload);
  },
  methods: {
    route_home() {
      this.$router.push({ name: "Home" }).catch((err) => {});
    },
    route_about_us() {
      this.$router.push({ name: "AboutUs" }).catch((err) => {});
    },
  },
  computed: {
    generalSpacingLeftRight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 5;
        case "sm":
          return 5;
        case "md":
          return 10;
        case "lg":
          return 13;
        case "xl":
          return 13;
        default:
          return 5;
      }
    },
    screenHeight() {
      return window.innerHeight;
    },
    headerLeftSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 0;
        case "md":
          return 50;
        case "lg":
          return 50;
        case "xl":
          return 50;
        default:
          return 0;
      }
    },
  },
};
</script>

<style scoped>
.header-subtitle {
  color: #243e93;
  font-size: 28px;
  font-weight: 800;
}
.content {
  color: #0f3d62;
  font-size: 20px;
  font-weight: 400;
}
</style>