<template>
  <div>
    <v-navigation-drawer
      app
      right
      width="500"
      v-model="drawer"
      dark
      color="#f5f9ff"
      class="hidden-md-and-up"
      v-if="ableToShowNavDrawer"
    >
      <v-toolbar dark flat color="transparent"></v-toolbar>
    </v-navigation-drawer>

    <v-app-bar
      flat
      :style="{
        paddingLeft: spacingLeftRight + '%',
        paddingRight: spacingLeftRight + '%',
      }"
      color="white"
      short
      clipped-left
      style="padding-top: 10px"
    >
      <v-toolbar-title>
        <div>
          <img src="../assets/my_logo.png" alt="my logo" width="40" />
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <div class="hidden-md-and-up">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </div> -->
      <div class="hidden-sm-and-down">
        <v-btn text large class="header-text" @click="scrollTo('introduction')"
          >Introduction</v-btn
        >
        <v-btn text large class="header-text" @click="route_about_us"
          >About Us</v-btn
        >
        <v-btn
          text
          large
          class="header-text"
          @click="scrollTo('focused_technologies')"
          >Focused Technologies</v-btn
        >
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      drawer: false,
    };
  },
  computed: {
    spacingLeftRight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 5;
        case "sm":
          return 5;
        case "md":
          return 10;
        case "lg":
          return 13;
        case "xl":
          return 13;
        default:
          return 5;
      }
    },
    ableToShowNavDrawer() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    },
  },
  methods: {
    scrollTo(id) {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: "smooth" });
    },
    route_about_us() {
      this.$router.push({ name: "AboutUs" }).catch((err) => {});
    },
  },
};
</script>

<style>
.header-text {
  text-transform: capitalize !important;
  letter-spacing: 1px !important;
}
</style>