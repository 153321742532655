<template>
  <v-main>
    <!-- Part 1 -->
    <div style="margin-top: 100px">
      <div
        :style="{
          paddingLeft: generalSpacingLeftRight + '%',
          paddingRight: generalSpacingLeftRight + '%',
        }"
      >
        <v-row class="align-center">
          <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
            <div
              :style="{
                paddingLeft: headerLeftSpacing + 'px',
                marginBottom: headerBottomMargin + 'px',
              }"
            >
              <div style="margin-bottom: 20px">
                <h1
                  style="
                    color: #243e93;
                    font-weight: 700;
                    font-size: 42px;
                    line-height: 55px;
                  "
                >
                  TECHTROMECH - TECH<span class="title-light">NOLOGY</span>,
                  <span class="title-light">ELEC</span>TRO<span
                    class="title-light"
                    >NICS</span
                  >
                  <span class="title-light">&</span> MECH<span
                    class="title-light"
                    >ANICAL</span
                  >
                </h1>
              </div>
              <div style="margin-bottom: 20px">
                <h2
                  style="
                    color: #f36823;
                    font-size: 32px;
                    font-weight: 500;
                    line-height: 55px;
                  "
                >
                  Bring Automation Into Our Daily Life.
                </h2>
              </div>
              <div style="margin-bottom: 20px">
                <v-btn
                  color="#f36823"
                  style="text-transform: capitalize !important"
                  dark
                  x-large
                  depressed
                  @click="scrollTo('introduction')"
                  >See More
                  <div class="ml-3">
                    <v-icon right dark size="25">
                      mdi-arrow-right-bold-circle-outline
                    </v-icon>
                  </div>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
            <div>
              <v-img src="../assets/header_1.png"></v-img>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- End Part 1 -->
    <!-- Part 2 - Introduction -->
    <div id="introduction" style="margin-top: 100px; margin-bottom: 100px">
      <div
        :style="{
          paddingLeft: generalSpacingLeftRight + '%',
          paddingRight: generalSpacingLeftRight + '%',
        }"
      >
        <v-row class="align-center">
          <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
            <div :style="{ paddingLeft: headerLeftSpacing + 'px' }">
              <div
                class="d-inline-flex flex-column"
                style="margin-bottom: 15px"
              >
                <div>
                  <p class="header-subtitle">
                    Who / What Is TECHTROMECH<span style="color: white">-</span
                    >?
                  </p>
                </div>
                <div>
                  <v-img
                    width="100%"
                    height="5"
                    src="../assets/underline.svg"
                  ></v-img>
                </div>
              </div>
              <div>
                <p class="content">
                  TECHTROMECH is a company who utilize state of the art
                  technology & electronics and in-house R&D mechanical to build
                  innovative products to help company / individual to better
                  digitalize their business.
                </p>
              </div>
              <!-- <div style="margin-bottom: 1000px;"> </div> -->
            </div>
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="6" lg="6" xl="6">
            <div>
              <v-img
                width="70%"
                class="mx-auto"
                src="../assets/techtrologo_big.png"
              ></v-img>
            </div>
          </v-col>
        </v-row>
        <div style="margin-top: 150px">
          <div :style="{ paddingLeft: headerLeftSpacing + 'px' }">
            <p class="header-subtitle">Company that using our product:</p>
            <div style="margin-top: 50px">
              <v-row align="center">
                <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                  <div>
                    <v-img
                      class="mx-auto"
                      width="70%"
                      src="../assets/customer_ingress.jpeg"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                  <div>
                    <v-img
                      class="mx-auto"
                      width="70%"
                      src="../assets/customer_nv.jpeg"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                  <div>
                    <v-img
                      class="mx-auto"
                      width="70%"
                      src="../assets/customer_pecca.jpeg"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                  <div>
                    <v-img
                      class="mx-auto"
                      width="70%"
                      src="../assets/customer_trobot.jpeg"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                  <div>
                    <v-img
                      class="mx-auto"
                      width="70%"
                      src="../assets/customer_rh.jpeg"
                    ></v-img>
                  </div>
                </v-col>
                <v-col cols="6" sm="6" xs="6" md="4" lg="3" xl="3">
                  <div>
                    <v-img
                      class="mx-auto"
                      width="70%"
                      src="../assets/customer_nyce.jpeg"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- End Part 2 -->
    <!-- Part 3 -->
    <div style="margin-top: 100px" id="focused_technologies">
      <div
        :style="{
          paddingLeft: narrowSpacingLeftRight + '%',
          paddingRight: narrowSpacingLeftRight + '%',
          paddingTop: wideSpacingLTopBottom + 'px',
          paddingBottom: wideSpacingLTopBottom + 'px',
        }"
        style="
          background-image: linear-gradient(
            90deg,
            rgba(195, 226, 255, 0.4),
            #fff
          );
        "
      >
        <v-row align="center">
          <v-col cols="12" sm="12" xs="12" md="4" lg="4" xl="4">
            <div>
              <div
                class="d-inline-flex flex-column"
                style="margin-bottom: 15px"
              >
                <div>
                  <p class="header-subtitle">Our Focus Technologies</p>
                </div>
                <div>
                  <v-img
                    width="100%"
                    height="5"
                    src="../assets/underline.svg"
                  ></v-img>
                </div>
              </div>
              <div>
                <p class="content">
                  Our team specialize in various technology.
                </p>
              </div>
            </div>
          </v-col>
          <v-col cols="12" sm="12" xs="12" md="8" lg="8" xl="8">
            <div>
              <v-carousel hide-delimiters :height="carouselHeight">
                <v-carousel-item v-for="(item, i) in carousel" :key="i">
                  <!-- carousel item 1 -->
                  <v-container fluid fill-height v-if="item == '1'">
                    <v-row>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img src="../assets/industrial-robot.png"></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">Robotics</p>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img
                              src="../assets/additive-technology.png"
                            ></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">
                              Additive Manufacturing
                            </p>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img src="../assets/web-technology.png"></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">Web Technologies</p>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img
                              src="../assets/mobile-technology.png"
                            ></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">
                              Mobile App Technologies
                            </p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                  <!-- carousel item 2 -->
                  <v-container fluid fill-height v-if="item == '2'">
                    <v-row>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img src="../assets/automation.png"></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">
                              Automation Technology
                            </p>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img src="../assets/computer-vision.png"></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">Computer Vision</p>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img src="../assets/electronics.png"></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">
                              Electrical & Electronics
                            </p>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img
                              src="../assets/artificial-intelligence.png"
                            ></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">
                              Artificial Intelligence
                            </p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                  <!-- carousel item 3 -->
                  <v-container fluid fill-height v-if="item == '3'">
                    <v-row>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img
                              src="../assets/mechanical-design.png"
                            ></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">
                              Mechanical Design
                            </p>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img src="../assets/simulation.png"></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">Simulation</p>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img src="../assets/cloud-technology.png"></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">
                              Cloud Technologies
                            </p>
                          </div>
                        </div>
                      </v-col>
                      <v-col
                        cols="6"
                        sm="6"
                        xs="6"
                        md="3"
                        lg="3"
                        xl="3"
                        style="text-align: center"
                      >
                        <div class="d-inline-flex flex-column">
                          <div class="orange-icon">
                            <v-img src="../assets/iot.png"></v-img>
                          </div>
                          <div
                            style="
                              margin-top: 10px;
                              text-align: center;
                              width: 100px;
                            "
                          >
                            <p class="carousel-description">
                              Internet of Things
                            </p>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- End Part 3 -->
  </v-main>
</template>

<script>
export default {
  data: function () {
    return {
      customers: [
        "customer_ingress.jpeg",
        "customer_nv.jpeg",
        "customer_pecca.jpeg",
        "customer_rh.jpeg",
        "customer_trobot.jpeg",
        "customer_nyce.jpeg",
        "customer_airscream.jpeg",
      ],
      carousel: ["1", "2", "3"],
    };
  },
  methods: {
    scrollTo(id) {
      const el = document.getElementById(id);
      el.scrollIntoView({ behavior: "smooth" });
    },
  },
  created() {
    var payload = {
      headerType: "needHeader",
    };
    this.$store.dispatch("updateHeaderType", payload);
  },
  computed: {
    generalSpacingLeftRight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 5;
        case "sm":
          return 5;
        case "md":
          return 10;
        case "lg":
          return 13;
        case "xl":
          return 13;
        default:
          return 5;
      }
    },
    narrowSpacingLeftRight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 5;
        case "sm":
          return 5;
        case "md":
          return 7;
        case "lg":
          return 9;
        case "xl":
          return 9;
        default:
          return 5;
      }
    },
    carouselHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 500;
        case "sm":
          return 500;
        case "md":
          return 200;
        case "lg":
          return 200;
        case "xl":
          return 200;
        default:
          return 300;
      }
    },
    wideSpacingLTopBottom() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 20;
        case "sm":
          return 20;
        case "md":
          return 100;
        case "lg":
          return 150;
        case "xl":
          return 150;
        default:
          return 20;
      }
    },
    headerLeftSpacing() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 0;
        case "md":
          return 100;
        case "lg":
          return 100;
        case "xl":
          return 100;
        default:
          return 0;
      }
    },
    headerBottomMargin() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 0;
        case "sm":
          return 0;
        case "md":
          return 150;
        case "lg":
          return 150;
        case "xl":
          return 150;
        default:
          return 0;
      }
    },
  },
};
</script>

<style scoped>
.title-light {
  font-weight: 400;
}

img {
  height: 100vh;
}

.header-subtitle {
  color: #243e93;
  font-size: 28px;
  font-weight: 800;
}

.content {
  color: #0f3d62;
  font-size: 20px;
  font-weight: 400;
}

.orange-icon {
  width: 100px;
  height: 100px;
  padding: 20px;
  border-radius: 1000px;
  background-color: #f36823;
  box-shadow: 0 10px 25px 5px rgb(188 60 28 / 30%);
}

.carousel-description {
  color: #4b6780;
  font-size: 18px;
  font-weight: 700;
}
</style>