const state = {
    headerType: '',
}

const actions = {
    updateHeaderType({ commit }, payload) {
        commit('updateHeaderType', payload)
    },

}

const getters = {
    getHeaderType: state => {
        return state.headerType
    },

}

const mutations = {
    updateHeaderType: (state, payload) => {
        state.headerType = payload.headerType;
    },

}

export default {
    state,
    actions,
    getters,
    mutations
}